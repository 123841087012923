<template>
    <v-card>
        <v-card-title class="text--m">{{ title }}</v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="mock"
                          disable-pagination
                          dense
                          height="160"
                          hide-default-footer></v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'LastInspections',
    props: {
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        headers () {
            return [
                {
                    text: this.$t('labels.id'),
                    value: 'id',
                    sortable: false
                },
                {
                    text: this.$t('labels.inspection_time'),
                    value: 'inspection_time',
                    sortable: false
                },
                {
                    text: this.$tc('labels.template', 1),
                    value: 'template',
                    sortable: false
                },
                {
                    text: this.$t('labels.location'),
                    value: 'location',
                    sortable: false
                }
            ];
        },
        mock () {
            return [
                {
                    id: 7878,
                    inspection_time: '2020-12-12 00:00:25',
                    template: 'template 1',
                    location: 'location 1'
                },
                {
                    id: 7879,
                    inspection_time: '2020-12-12 00:00:25',
                    template: 'template 1',
                    location: 'location 1'
                },
                {
                    id: 7876,
                    inspection_time: '2020-12-12 00:00:25',
                    template: 'template 1',
                    location: 'location 1'
                },
                {
                    id: 7873,
                    inspection_time: '2020-12-12 00:00:25',
                    template: 'template 1',
                    location: 'location 1'
                },
                {
                    id: 7870,
                    inspection_time: '2020-12-12 00:00:25',
                    template: 'template 1',
                    location: 'location 1'
                }
            ];
        }
    }
};
</script>

<style scoped>

</style>
