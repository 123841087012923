<template>
    <div>
        <BaseIcon class="noshrink clickable"
                  width="23"
                  height="23"
                  @click="getQRCode">
            <QRIcon :color="'#fff'"></QRIcon>
        </BaseIcon>

        <Modal :dialog="dialog"
               @visibility="dialog = $event"
               max-width="400px"
               @close="dialog = false">
            <template slot="content">
                <v-img :src="url"></v-img>
            </template>
        </Modal>
    </div>
</template>

<script>
import BaseIcon from '@/components/templates/BaseIcon';
import QRIcon from '@/components/icons/QRIcon';
import Modal from '@/components/templates/Modal';

export default {
    name: 'QRCode',
    components: { BaseIcon, QRIcon, Modal },
    props: {
        url: {
            type: String,
            'default': ''
        },
        handler: {
            type: Function,
            required: true
        }
    },
    data: () => ({
        dialog: false
    }),
    methods: {
        getQRCode () {
            this.handler()
                .then(() => {
                    this.dialog = true;
                });
        }
    }
};
</script>

<style scoped>

</style>
