<template>
    <v-card>
        <v-card-text>
            <v-layout align-center justify-space-between>
                <span class="text--m font-weight-medium">{{ title }}</span>
                <span class="font-weight-medium text--xxl secondary--text d-inline-block ml-4">{{ amount }}</span>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'SimpleStat',
    props: {
        amount: {
            type: [String, Number],
            required: true
        },
        title: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
</style>
