<template>
    <router-link :to="{ name: 'home' }">
        <v-img :src="path"
               v-bind="$attrs"
               alt="Koios"
               :width="width"
               :height="height"></v-img>
    </router-link>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Logo',
    props: {
        light: {
            type: Boolean,
            'default': false
        },
        width: {
            'default': 230,
            type: [Number, String]
        },
        height: {
            'default': 80,
            type: [Number, String]
        },
        mini: {
            type: Boolean,
            'default': false
        },
        customLogo: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapState('generalConfigurations', [
            'host'
        ]),
        path () {
            if (this.customLogo) {
                // na localhost nie działa logo
                return `/web_logos/${this.host}_WEB_LOGO.png`;
            } else {
                if (this.light) {
                    return this.mini ? require('@/assets/images/koios_white_mini.png') : require('@/assets/images/Koios_white.png');
                }
                return require('@/assets/images/Koios_black.png');
            }
        }
    }
};
</script>

<style scoped>

</style>
