<template>
    <v-card class="full-height">
        <v-card-title class="text--m">
            <div style="width: 100%">
                {{ title }}
                <v-divider></v-divider>
            </div>
        </v-card-title>
        <v-card-text>
            <v-layout align-center
                      justify-space-between
                      :class="[item.total ? 'mt-5' : 'mt-3']"
                      v-for="(item, i) in values"
                      :key="i">
                <span :class="{'font-weight-medium': item.total}">{{ item.text }}</span>
                <span class="text--m d-inline-block ml-4"
                      :class="{'font-weight-medium secondary--text': item.total}">{{ item.amount }}</span>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'MultiStat',
    props: {
        title: {
            type: String,
            required: true
        },
        values: {
            type: Array,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
</style>
