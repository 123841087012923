<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 50 50"
         @click="$emit('click')"
         :width="width"
         :height="height">
        <slot></slot>
    </svg>
</template>

<script>
export default {
    name: 'BaseIcon',
    props: {
        width: {
            type: [String, Number],
            'default': 50
        },
        height: {
            type: [String, Number],
            'default': 50
        }
    }
};
</script>

<style scoped>

</style>
