<template>
    <v-card>
        <v-card-title class="text--m">{{ title }}</v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="mock"
                          disable-pagination
                          dense
                          height="160"
                          hide-default-footer></v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'LastIncidents',
    props: {
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        headers () {
            return [
                {
                    text: this.$t('labels.number'),
                    value: 'number',
                    sortable: false
                },
                {
                    text: this.$t('labels.date_of_occurrence'),
                    value: 'date_of_occurrence',
                    sortable: false
                },
                {
                    text: this.$t('labels.type'),
                    value: 'type',
                    sortable: false
                },
                {
                    text: this.$t('labels.status'),
                    value: 'status',
                    sortable: false
                }
            ];
        },
        mock () {
            return [
                {
                    number: 7878,
                    date_of_occurrence: '2020-12-12 00:00:25',
                    type: 'type 1',
                    status: 'status 1'
                },
                {
                    number: 7879,
                    date_of_occurrence: '2020-12-12 00:00:25',
                    type: 'type 1',
                    status: 'status 1'
                },
                {
                    number: 7876,
                    date_of_occurrence: '2020-12-12 00:00:25',
                    type: 'type 1',
                    status: 'status 1'
                },
                {
                    number: 7873,
                    date_of_occurrence: '2020-12-12 00:00:25',
                    type: 'type 1',
                    status: 'status 1'
                },
                {
                    number: 7870,
                    date_of_occurrence: '2020-12-12 00:00:25',
                    type: 'type 1',
                    status: 'status 1'
                }
            ];
        }
    }
};
</script>

<style scoped>

</style>
