<template>
    <Page>
        <template slot="main">
              <div>
                <h1>Work in progress</h1>
              </div>
            <v-container fluid>
                <v-row>
                    <v-col lg="3"
                           v-for="(stat, i) in statsAmounts"
                           :key="i">
                        <SimpleStat :amount="stat.amount" :title="stat.title"></SimpleStat>
                    </v-col>
                </v-row>

                <v-row class="mt-10" align="stretch">
                    <v-col cols="12">
                        <h2 class="font-weight-medium">{{ $t('navigation.inspections') }}</h2>
                    </v-col>
                    <v-col lg="9">
                        <LastInspections :title="$t('labels.last_inspections')"></LastInspections>
                    </v-col>
                    <v-col lg="3">
                        <MultiStat :title="$t('dashboard.stats.inspections_number')" :values="inspectionsStats"></MultiStat>
                    </v-col>
                </v-row>

                <v-row class="mt-10" align="stretch">
                    <v-col cols="12">
                        <h2 class="font-weight-medium">{{ $t('navigation.incidents') }}</h2>
                    </v-col>
                    <v-col lg="9">
                        <LastIncidents :title="$t('labels.last_incidents')"></LastIncidents>
                    </v-col>
                    <v-col lg="3">
                        <MultiStat :title="$t('dashboard.stats.incidents_number')" :values="incidentsStats"></MultiStat>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </Page>
</template>

<script>
import Page from '@/components/templates/Page';
import SimpleStat from '@/components/widgets/dashboard/SimpleStat';
import MultiStat from '@/components/widgets/dashboard/MultiStat';
import LastInspections from '@/components/widgets/dashboard/LastInspections';
import LastIncidents from '@/components/widgets/dashboard/LastIncidents';

export default {
    name: 'Home',
    components: { Page, SimpleStat, MultiStat, LastInspections, LastIncidents },
    computed: {
        statsAmounts () {
            return [
                {
                    amount: 234,
                    title: this.$t('dashboard.stats.customers_number')
                },
                {
                    amount: 314,
                    title: this.$t('dashboard.stats.sites_number')
                },
                {
                    amount: 456,
                    title: this.$t('dashboard.stats.locations_number')
                },
                {
                    amount: 34,
                    title: this.$t('dashboard.stats.users_number')
                }
            ];
        },
        inspectionsStats () {
            return [
                {
                    amount: 23,
                    text: this.$t('labels.today')
                },
                {
                    amount: 230,
                    text: this.$t('labels.month')
                },
                {
                    amount: 3456,
                    text: this.$t('labels.year')
                },
                {
                    amount: 4567,
                    text: this.$t('labels.total'),
                    total: true
                }
            ];
        },
        incidentsStats () {
            return [
                {
                    amount: 3,
                    text: this.$t('labels.today')
                },
                {
                    amount: 234,
                    text: this.$t('labels.month')
                },
                {
                    amount: 450,
                    text: this.$t('labels.year')
                },
                {
                    amount: 1200,
                    text: this.$t('labels.total'),
                    total: true
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
