<template>
    <div class="d-inline-block">
        <a class="d-inline-block"
           :class="{'font-weight-medium': boldText}"
           @click="logout"
           data-test="logoutBtn">
            <v-icon :size="iconSize">{{ ICONS.LOGOUT }}</v-icon>
            {{ $t('auth.logout') }}</a>
    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';

export default {
    name: 'Logout',
    props: {
        iconSize: {
            type: [String, Number],
            'default': 24
        },
        boldText: {
            type: Boolean,
            'default': true
        }
    },
    data: () => ({
        ICONS
    }),
    methods: {
        logout () {
            let _previousRoute;
            if (Object.prototype.hasOwnProperty.call(this.$route, 'name') && this.$route.name) {
                _previousRoute = {
                    name: this.$route.name
                };
            } else {
                _previousRoute = {
                    path: this.$route.path
                };
            }
            this.$store.commit('authorization/SET_PREVIOUS_ROUTE', _previousRoute);
            this.$store.dispatch('authorization/signOut');
        }
    }
};
</script>

<style scoped>

</style>
